import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Nav from "./Nav";
import dayjs from "dayjs";
import { Helmet } from "react-helmet-async";

const augustaLocation = {
  line1: "2479 Doug Barnard Pkwy",
  line2: "Augusta, GA 30906",
  line3: "TEL: (706) 790 - 8111",
  line4: "FAX: (706) 790 - 4368",
  name: "AUGUSTA",
};
const northAugustaLocation = {
  line1: "1182 Edgefield Rd",
  line2: "North Augusta, SC 29841",
  line3: "TEL: (803) 613 - 0101",
  line4: "FAX: (803) 613 - 0323",
  name: "NORTH AUGUSTA",
};

let tmpReceipt = {
  message: "",
  reference: "",
  date: "",
  paymentId: "",
  location: "",
  labor: 0,
  parts: 0,
  gog: 0,
  misc: 0,
  shopFee: 0,
  subtotal: 0,
  tax: 0,
  fees: 0,
  total: 0,
  services: 0,
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [location, setLocation] = useState(augustaLocation);
  const [receipt, setReceipt] = useState(tmpReceipt);

  const { id } = useParams();
  const webLocation = useLocation();

  let axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 1000,
  });

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (id && id.length > 5) {
        axiosInstance
          .get(`/util/receipt/${id}`)
          .then((res) => {
            setReceipt(res.data);
            if (res.data.location === "augusta") {
              setLocation(augustaLocation);
            } else if (res.data.location === "northAugusta") {
              setLocation(northAugustaLocation);
            }
            setFailed(false);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            setFailed(true);
            setTimeout(() => setLoading(false), 700);
          });
      } else {
        setFailed(true);
        setTimeout(() => setLoading(false), 700);
      }
    }
    return () => {
      inView = false;
    };
  }, [id]);

  const formatCurrency = (val) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(val);
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen bg-slate-200">
      <Nav />
      {!loading && !failed && (
        <div className="flex flex-col items-center justify-start w-full h-full overflow-y-auto 2xl:justify-center xl:py-0">
          <Helmet>
            <title>Receipt | {receipt.reference} | HiTech Power Systems</title>
          </Helmet>
          <div className="w-full h-20 xl:hidden" />
          <div className="w-10/12 md:w-[450px] bg-white h-auto flex flex-col justify-start items-center py-10 md:py-8 px-6 shadow-md  text-xs md:text-base">
            <p className="text-lg font-semibold">HI-TECH POWER SYSTEMS</p>
            <p className="text-sm uppercase">{location.line1}</p>
            <p className="text-sm uppercase">{location.line2}</p>
            <p className="text-sm uppercase">{location.line3}</p>
            <p className="text-sm uppercase">{location.line4}</p>
            <div className="w-full mt-2 border-t border-gray-300 md:mt-3" />
            <p className="mt-2 text-lg font-semibold md:text-xl md:mt-3">RECEIPT</p>
            <div className="w-full mt-2 border-t border-gray-300 md:mt-3" />
            <div className="flex flex-row items-center justify-between w-full mt-2 md:mt-3">
              <p className="w-24 font-semibold uppercase line-clamp-1">REFERENCE:</p>
              <p className="text-right uppercase">{receipt.reference}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-3">
              <p className="w-16 font-semibold uppercase line-clamp-1 xl:w-28">TX DATE:</p>
              <p className="uppercase">{dayjs(receipt.date).format("MM/DD/YYYY")}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-3">
              <p className="w-16 font-semibold uppercase line-clamp-1 xl:w-28">TX TIME:</p>
              <p className="uppercase truncate">{dayjs(receipt.date).format("hh:mm A")}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-3">
              <p className="w-16 font-semibold uppercase line-clamp-1">TX ID:</p>
              <p className="uppercase truncate">{receipt.paymentId.replace("pi_", "")}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-3">
              <p className="font-semibold uppercase">LOCATION:</p>
              <p className="uppercase truncate">{location.name}</p>
            </div>
            <div className="w-full mt-3 border-t border-gray-300" />
            <div className="flex flex-row items-center justify-between w-full mt-3">
              <p className="font-semibold uppercase">ITEM</p>
              <p className="font-semibold uppercase">PRICE</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-3">
              <p className="uppercase">Labor</p>
              <p className="uppercase">{formatCurrency(receipt.labor || 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-1">
              <p className="uppercase">Parts</p>
              <p className="uppercase">{formatCurrency(receipt.parts || 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-1">
              <p className="uppercase">Gas, Oil & Grease</p>
              <p className="uppercase">{formatCurrency(receipt.gog || 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-1">
              <p className="uppercase">Freight</p>
              <p className="uppercase">{formatCurrency(receipt.freight || 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-1">
              <p className="uppercase">Services</p>
              <p className="uppercase">{formatCurrency(receipt.services || 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-1">
              <p className="uppercase">Miscellaneous</p>
              <p className="uppercase">{formatCurrency(receipt.misc || 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-1">
              <p className="uppercase">Shop Fees</p>
              <p className="uppercase">{formatCurrency(receipt.shopFee || 0)}</p>
            </div>
            <div className="w-full mt-3 border-t border-gray-300" />
            <div className="flex flex-row items-center justify-between w-full mt-3">
              <p className="font-semibold uppercase">SUBTOTAL:</p>
              <p className="uppercase">{formatCurrency(receipt.subtotal || 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-1">
              <p className="font-semibold uppercase">TAX:</p>
              <p className="uppercase">{formatCurrency(receipt.tax || 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-1">
              <p className="font-semibold uppercase">FEES:</p>
              <p className="uppercase">{formatCurrency(receipt.fees || 0)}</p>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-1">
              <p className="font-semibold uppercase">TOTAL:</p>
              <p className="font-semibold uppercase">{formatCurrency(receipt.total || 0)}</p>
            </div>
            <div className="w-full mt-3 border-t border-gray-300" />
            <div className="flex flex-row items-center justify-center w-full mt-1">
              <p className="mt-2 text-xs">
                Paid with <span className="font-semibold uppercase">{receipt.cardType ? receipt.cardType : "card"}</span> ending in{" "}
                <span className="font-semibold">{receipt.last4 ? receipt.last4 : "xxxx"}</span>
              </p>
            </div>
            <div className="w-full mt-3 border-t border-gray-300" />
            <p className="mt-5 text-sm">Thank you for your business!</p>
          </div>
        </div>
      )}
      {!loading && failed && (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <Helmet>
            <title>Not Found | HiTech Power Systems Receipts</title>
          </Helmet>
          <div className="bg-white p-8 flex flex-col justify-start items-center shadow-md max-w-[500px]">
            <p className="text-lg font-semibold">HI-TECH POWER SYSTEMS</p>
            <p className="text-sm text-center">
              The page {webLocation.pathname} does not exist. You may have mistyped the address, or the page may have been moved or deleted.
            </p>
            <a href="http://www.hitechpowersystems.com/" className="mt-4 text-xs font-bold uppercase duration-150 hover:text-slate-800">
              Return to the homepage
            </a>
          </div>
        </div>
      )}
      {loading && !failed && (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <Helmet>
            <title>Loading | HiTech Power Systems Receipts</title>
          </Helmet>
          <div className="bg-white p-8 flex flex-col justify-start items-center shadow-md max-w-[500px]">
            <p className="text-lg font-semibold">HI-TECH POWER SYSTEMS</p>
            <p className="text-sm text-center">Loading ...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
