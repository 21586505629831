import { useEffect } from "react";

const Redirect = () => {
  useEffect(() => {
    let inView = true;
    if (inView) {
      window.location.href = "http://www.hitechpowersystems.com/";
    }
    return () => {
      inView = false;
    };
  }, []);

  return null;
};

export default Redirect;
