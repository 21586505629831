import { useLocation } from "react-router-dom";
import Nav from "./Nav";
import { Helmet } from "react-helmet-async";

const Error404 = () => {
  const location = useLocation();

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen bg-slate-200">
      <Nav />
      <div className="flex flex-col items-center justify-center w-full h-full">
        <Helmet>
          <title>Not Found | HiTech Power Systems Receipts</title>
        </Helmet>
        <div className="bg-white p-8 flex flex-col justify-start items-center shadow-md max-w-[500px]">
          <p className="text-lg font-semibold">HI-TECH POWER SYSTEMS</p>
          <p className="text-sm text-center">
            The page {location.pathname} does not exist. You may have mistyped the address, or the page may have been moved or deleted.
          </p>
          <a href="http://www.hitechpowersystems.com/" className="mt-4 text-xs font-bold uppercase duration-150 hover:text-slate-800">
            Return to the homepage
          </a>
        </div>
      </div>
    </div>
  );
};

export default Error404;
