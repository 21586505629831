import Logo from "./assets/hitechLogo3.png";

const Nav = () => {
  return (
    <div className="flex flex-row items-center justify-center w-full h-16 text-white bg-black">
      <div className="flex flex-row justify-between items-center max-w-[1440px] w-full h-16 px-5">
        <a href="https://hitechpowersystems.com" target="_blank" rel="noreferrer">
          <img src={Logo} className="w-auto h-8 cursor-pointer md:h-12" alt="HiTech Power Systems Logo" />
        </a>
        <div className="flex flex-row items-center justify-end gap-3">
          <a
            href="https://liveuptothehype.com/?source=hitechreceiptsplatform"
            className="px-3 py-1 text-[10px] md:text-xs hover:text-gray-200 cursor-pointer transition-all ease-in-out duration-150 text-right"
            target="_blank"
            rel="noreferrer"
          >
            Powered by Hypertek Solutions LLC
          </a>
        </div>
      </div>
    </div>
  );
};

export default Nav;
